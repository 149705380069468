window.addEventListener('DOMContentLoaded', event => {
  // Activate feather
  feather.replace();

  // Enable tooltips globally
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  // Enable popovers globally
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl);
  });

  // Toggle the side navigation
  const sidebarToggle = document.body.querySelector('#sidebarToggle');
  if (sidebarToggle) {
      // Uncomment Below to persist sidebar toggle between refreshes
      // if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
      //     document.body.classList.toggle('sidenav-toggled');
      // }
      sidebarToggle.addEventListener('click', event => {
          event.preventDefault();
          document.body.classList.toggle('sidenav-toggled');
          localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sidenav-toggled'));
      });
  }

  // Close side navigation when width < LG
  const sidenavContent = document.body.querySelector('#layoutSidenav_content');
  if (sidenavContent) {
      sidenavContent.addEventListener('click', event => {
          const BOOTSTRAP_LG_WIDTH = 992;
          if (window.innerWidth >= 992) {
              return;
          }
          if (document.body.classList.contains("sidenav-toggled")) {
              document.body.classList.toggle("sidenav-toggled");
          }
      });
  }

  // Add active state to sidbar nav links
  let activatedPath = window.location.pathname.match(/([\w-]+\.html)/, '$1');

  if (activatedPath) {
      activatedPath = activatedPath[0];
  } else {
      activatedPath = 'index.html';
  }

  const targetAnchors = document.body.querySelectorAll('[href="' + activatedPath + '"].nav-link');

  targetAnchors.forEach(targetAnchor => {
      let parentNode = targetAnchor.parentNode;
      while (parentNode !== null && parentNode !== document.documentElement) {
          if (parentNode.classList.contains('collapse')) {
              parentNode.classList.add('show');
              const parentNavLink = document.body.querySelector(
                  '[data-bs-target="#' + parentNode.id + '"]'
              );
              parentNavLink.classList.remove('collapsed');
              parentNavLink.classList.add('active');
          }
          parentNode = parentNode.parentNode;
      }
      targetAnchor.classList.add('active');
  });
});


//ここからchat page script
// カテゴリーボタンにイベントリスナーを追加
const categoryButtons = document.querySelectorAll('.category-btn');
if (categoryButtons.length > 0) {
    categoryButtons.forEach(button => {
        button.addEventListener('click', (e) => {
            const categoryId = e.target.getAttribute('data-category-id');
            const url = `/${categoryId}/category_posts`;
            
            // 新しいタブでURLを開く
            window.open(url, '_blank');
        });
    });
}

// const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

// チャットセクション
const chatInput = document.querySelector('.chat-input textarea');
const sendChatBtn = document.querySelector('.chat-input span');
const chatbox = document.querySelector('.chatbox');

if (chatInput && sendChatBtn && chatbox) {
    let userMessage;
    const API_KEY = "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";
    const inputInitHeight = chatInput.scrollHeight;

    const createChatLi = (message, className) => {
        const chatLi = document.createElement('li');
        chatLi.classList.add('chat', className);
        let chatContent = className === "outgoing" ? `<p></p>` : `<span class="material-symbols-outlined">smart_toy</span><p></p>`;
        chatLi.innerHTML = chatContent;
        chatLi.querySelector('p').textContent = message;
        return chatLi;
    }

    const typeMessage = (message, messageElement) => {
        let index = 0;
        const interval = setInterval(() => {
            if (index < message.length) {
                messageElement.innerHTML += message.charAt(index);
                index++;
            } else {
                clearInterval(interval);
            }
        }, 50);
    };

    const generateResponse = (incomingChatLi) => {
        const messageElement = incomingChatLi.querySelector('p');
        fetch('/get-response', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'X-CSRFToken': csrfToken
            },
            body: JSON.stringify({ message: userMessage })
        })
            .then(response => response.json())
            .then(data => {
                const message = data.message.replace(/\n/g, "");
                messageElement.innerHTML = '';
                typeMessage(message, messageElement);
            })
            .catch((error) => {
                messageElement.classList.add('error');
                messageElement.textContent = "一時的なエラーです。少し時間をあけてから、再度お試しください。";
            })
            .finally(() => chatbox.scrollTo(0, chatbox.scrollHeight));
    }

    const handleChat = () => {
        userMessage = chatInput.value.trim();
        if (!userMessage) return;
        chatInput.value = "";
        chatbox.appendChild(createChatLi(userMessage, "outgoing"));
        chatbox.scrollTop = chatbox.scrollHeight;
        setTimeout(() => {
            const incomingChatLi = createChatLi("Thinking...", "incoming")
            chatbox.appendChild(incomingChatLi);
            generateResponse(incomingChatLi);
        }, 600);
    }

    chatInput.addEventListener('input', () => {
        chatInput.style.height = `${inputInitHeight}px`;
        chatInput.style.height = `${chatInput.scrollHeight}px`;
    });

    chatInput.addEventListener('keydown', (e) => {
        if (e.key === "Enter" && !e.shiftKey && window.innerWidth > 800) {
            e.preventDefault();
            handleChat();
        }
    });

    sendChatBtn.addEventListener('click', handleChat);
}

//カテゴリーボタンクリック計測用
document.addEventListener('DOMContentLoaded', function() {
    // const csrfTokenMeta = document.querySelector('meta[name="csrf-token"]');
    const categoryButtons = document.querySelectorAll('.category-buttons .category-btn');

    if (categoryButtons.length > 0) {//csrfTokenMeta &&

        // const csrfToken = csrfTokenMeta.getAttribute('content');

        categoryButtons.forEach(button => {
            button.addEventListener('click', function() {
                const categoryId = this.dataset.categoryId;
                const category = this.dataset.category;
                
                fetch('/api/category-click', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        // 'X-CSRFToken': csrfToken,
                        // 'X-Requested-With': 'XMLHttpRequest'
                    },
                    body: JSON.stringify({ 
                        categoryId,
                        category
                    }),
                })
                .then(response => response.json())
                .then(data => console.log('Category click recorded:', data))
                .catch((error) => console.error('Error:', error));
            });
        });
    } else {
        console.log('Required elements not found. CSRF token or category buttons may be missing.');
    }
});
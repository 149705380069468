import { createApp } from 'vue';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/js/all.min.js';
// import '@fortawesome/fontawesome-free/css/all.min.css';

// 既存のscripts.jsをインポート
import '../js/scripts.js';

document.addEventListener('DOMContentLoaded', () => {
    // CKEditorの初期化（CKEditorを使用する場合）
    if (typeof ClassicEditor !== 'undefined') {
        ClassicEditor
            .create(document.querySelector('#editor'))
            .catch(error => {
                console.error(error);
            });
    }

    // Vue アプリケーションの初期化
    const app = createApp({
        data() {
            return {
                // ここにデータを追加
            }
        },
        methods: {
            // ここにメソッドを追加
        },
        mounted() {
            // コンポーネントがマウントされた後に実行されるコード
            // 例: Bootstrapのツールチップを初期化
            const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
            tooltipTriggerList.map(function (tooltipTriggerEl) {
                return new bootstrap.Tooltip(tooltipTriggerEl)
            });
        }
    });

    // グローバルコンポーネントの登録（必要な場合）
    // app.component('component-name', ComponentDefinition);

    // app.mount('#app');
});
